import React, { useState, useEffect } from 'react';
import axios from 'axios';
import GameComponent from './gamecomponent';
import ContestCountdown from '../helpers/countdown';
import Navbar from '../../nav';
import { Link, useParams } from 'react-router-dom';
import Loader from '../../loader';
import Join from './join';
import ContestResults from './results';
// import ProgressBar from '../helpers/progerssbar';

const MainComponent = () => {
    const [timeUntilStart, setTimeUntilStart] = useState(null);
    const [contestDetails, setContestDetails] = useState(null);
    const [loading, setLoading] = useState(true);

    const [user_has_joined_contest, setUserHasJoinedContest] = useState(false);
    const [user_has_completed_contest, setUserHasCompletedContest] = useState(false);
    const [user, setUser] = useState(null);

    const { contest_id } = useParams()

    const [access_token, setAccessToken] = useState(localStorage.getItem('verified_access_token'))

    useEffect(() => {
        (async () => {
            setLoading(true)
            console.log("getting user info");
            let access_token = localStorage.getItem('verified_access_token')
            if (localStorage.getItem('verified_access_token') === null) {
                window.location.href = '/#/join';
            } else {
                let response = await axios.get(`${process.env.REACT_APP_API_URL}/me`, {
                    headers: {
                        'Authorization': `Bearer ${access_token}`
                    }
                })
                const { signed_in } = response.data
                if (!signed_in) {
                    window.location.href = '/#/join';
                }
                // http://localhost:8000/contests/a5ee289e0c924758991f1f5ead23f321/rank
                setUser(response.data.user)
                setAccessToken(access_token)

            }
        })()
    }, [access_token])

    useEffect(() => {
        const fetchContestDetails = async () => {
            try {
                setLoading(true);
                console.log("now loading contest details");
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/contests/` + contest_id + '/details', {
                    headers: {
                        'Authorization': `Bearer ${access_token}`
                    }
                });

                const { time_until_start, joining_status } = response.data[0];
                setTimeUntilStart(time_until_start);
                setContestDetails(response.data[0]);


                if (joining_status === "pending") {
                    setUserHasJoinedContest(false)
                } else if (joining_status === "completed") {
                    setUserHasCompletedContest(true)
                } else if (joining_status === "joined") {
                    setUserHasJoinedContest(true)
                }

                setLoading(false);
            } catch (error) {
                console.error('Error fetching contest details:', error);
            }
        };

        if (!contestDetails && access_token) {
            fetchContestDetails();
        }
    }, [contestDetails, contest_id, access_token]);

    const loadContestResults = async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/contests/` + contest_id + '/rank', {
            headers: {
                'Authorization': `Bearer ${access_token}`
            }
        });

        console.log(response.data);
    }



    return (
        <div>
            {loading ? (
                <div className='mt-10'>
                    <Loader />
                </div>
            ) : (
                <div>
                    <Navbar credit_balance={user?.credit_balance} />

                    <div className="p-4 pt-6">
                        {user_has_completed_contest ? (
                            <div>
                                <div className='p-4 alert-default bg-green-200 text-sm text-left rounded-lg'>
                                    <p>View contest results</p>

                                    <button className='btn btn-primary btn-sm mr-2' onClick={() => {
                                        window.location.reload()
                                    }}>Refresh </button>



                                    <Link to={'/topics'} className='btn btn-sm btn-secondary'>View active contests</Link>
                                </div>

                                <ContestResults contestId={contestDetails.id} access_token={access_token}></ContestResults>

                            </div>
                        ) : user_has_joined_contest ? (
                            <ContestCountdown remaining_time={timeUntilStart} contest_details={contestDetails}>
                                <GameComponent contest_details={contestDetails} access_token={access_token} />
                            </ContestCountdown>
                        ) : (
                            <Join contest_details={contestDetails} user={user} />
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default MainComponent;
